<template >
  <div class="schoolManager">
    <div class="logButton clearFix">
      <div class="export">
        <el-button class="confirmButton" @click="addSchool">添加学校</el-button>
        <!-- <el-button class="confirmButton" @click="flush">刷新数据</el-button> -->
      </div>
      <div class="search">
        <!-- 搜索题目 -->
        <div class="searchContent">
          <el-input
            placeholder="请输入学校名称"
            v-model="input"
            clearable
            @clear="handleSearchColleague"
            class="input-with-select"
          ></el-input>
        </div>
        <el-button class="confirmButton" @click="handleSearchColleague">搜索</el-button>
      </div>
    </div>
    <!-- 题目列表 -->
    <el-card>
      <el-table ref="multipleTable" :data="schoolList" tooltip-effect="dark" style="width: 100%">
        <!-- 序号 -->
        <el-table-column type="index" :index="indexMethod" label="序号" width="100"></el-table-column>
        <!-- 头像 -->
        <el-table-column label="Logo">
          <template slot-scope="scope">
            <img v-if="scope.row.pic" class="wx_img" :src="scope.row.pic" />
            <div v-else class="wx_img"></div>
          </template>
        </el-table-column>
        <!--姓名  -->
        <el-table-column prop="name" label="学校名称"></el-table-column>
        <el-table-column prop="province" label="省份"></el-table-column>
        <el-table-column prop="leaderName" label="负责人"></el-table-column>
        <el-table-column prop="expdata_student_num" label="虚拟实验使用人数"></el-table-column>
        <el-table-column prop="expdata_total_num" label="虚拟实验数据总数"></el-table-column>
        <el-table-column prop="book_total" label="预约数据总数"></el-table-column>
        <el-table-column prop="exam_total" label="考试数据总数"></el-table-column>
        <el-table-column prop="report_total" label="报告数据总数"></el-table-column>
        <el-table-column label="授权虚拟实验">
          <template slot-scope="scope">
            <div
              class="operatorItem floatLeft"
              @click="gograntProject(scope.row.id)"
            >{{scope.row.grantExpNumber}}</div>
          </template>
        </el-table-column>
        <!-- 操作-->
        <el-table-column min-width="120" label="操作">
          <template slot-scope="scope">
            <div class="operatorItem floatLeft" @click="uploadPic(scope.row)">上传logo</div>
            <div class="operatorItem floatLeft" @click="updatePic(scope.row)">修改信息</div>
            <!-- <div class="operatorItem floatLeft" @click="bindCourse(scope.row)">绑定课程</div> -->
            <div class="operatorItem floatLeft" @click="deletePic(scope.row)">删除</div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 题目分页 -->
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="page"
        background
        :page-size="pageSize"
        layout="prev, pager, next"
        :total="schoolListTotal"
      ></el-pagination>
    </el-card>
    <el-dialog :title="logoTitle" :visible.sync="logoVisible" width="30%">
      <span class="dialog-span">
        <el-upload
          class="avatar-uploader el-upload-padding"
          :action="uploadSchoolLogoFilePath"
          :show-file-list="false"
          :data="{id:course_id}"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
        >
          <img v-if="schoolPic" :src="schoolPic" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button class="cancelButton" @click="logoVisible = false">取 消</el-button>
        <el-button class="confirmButton" @click="confirmAdd">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="infoTitle" :visible.sync="infoVisible" width="30%">
      <span class="dialog-span">
        <el-form label-width="100px" label-position="left">
          <el-form-item label="学校Logo">
            <el-upload
              class="avatar-uploader el-upload-padding"
              :action="uploadSchoolLogoFilePath"
              :show-file-list="false"
              :data="{id:course_id}"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <img v-if="schoolPic" :src="schoolPic" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>

          <el-form-item label="学校代码">
            <el-input v-model="schoolID" :disabled="sign == 'update'" placeholder="请输入学校代码"></el-input>
          </el-form-item>
          <el-form-item label="学校名称">
            <el-input v-model="schoolName" placeholder="请输入学校名称"></el-input>
          </el-form-item>
          <el-form-item label="学校管理员">
            <el-select class="width100" v-model="user_id" filterable remote reserve-keyword placeholder="请输入姓名搜索"
            :remote-method="remoteMethod" :loading="loading">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="绑定课程">
            <el-select class="width100" v-model="course_id" multiple filterable remote reserve-keyword placeholder="请选择绑定课程"
            :remote-method="searchCourse" :loading="loading">
              <el-option v-for="item in courseList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
            <div class="tips">该课程对应小程序安全试题库功能</div>
          </el-form-item>
          <!-- <el-form-item label="绑定课程">
            <el-select v-model="course_id" multiple placeholder="请选择绑定课程" remote :remote-method="searchCourse"  :loading="loading" filterable>
              <el-option
                v-for="item in courseList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
            <div class="tips">该课程对应小程序安全试题库功能</div>
          </el-form-item> -->
          <el-form-item label="实验中心名称">
            <el-input v-model="exp_center_name" placeholder="请输入实验中心名称"></el-input>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button class="cancelButton" @click="infoVisible = false">取 消</el-button>
        <el-button class="confirmButton" @click="confirmUpdate">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 授权项目弹窗 -->
    <el-dialog
      class="grantBox"
      title="授权项目"
      :visible.sync="grantVisible"
      @close="closeVisible"
      width="70%"
    >
      <div class="checkAll">
        <el-checkbox v-model="checkedAll" @change="toggleAll"></el-checkbox>全选
      </div>
      <el-table
        ref="multipleTable"
        :data="tableData"
        row-key="lab_id"
        :expand-row-keys="expandedkeys"
        :tree-props="{children: 'explist', hasChildren: 'hasChildren'}"
      >
        <el-table-column width="70">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.buystatus" @change="toggleChecked(scope.row)"></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column prop="lab_id" label="分类id" min-width="30"></el-table-column>
        <el-table-column prop="lab_name" label="分类名称"></el-table-column>

        <!-- <el-table-column
                prop="buyUsername"
                min-width="30"
                label="购买者">
        </el-table-column>-->
        <el-table-column prop="username" min-width="30" label="开发者"></el-table-column>
        <el-table-column prop="schoolName" min-width="30" label="学校"></el-table-column>
        <el-table-column prop="des" label="简介"></el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button class="themecancelButton" @click="grantVisible = false">取消</el-button>
        <el-button class="themeButton" @click="grantProject">确定</el-button>
      </span>
    </el-dialog>
    <!-- <el-dialog title="绑定课程" :visible.sync="courseVisible" width="40%">
        <span>
          <el-select v-model="course_id" multiple placeholder="请选择" class="width100">
            <el-option
              v-for="item in courseList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </span>
        <span slot="footer" class="dialog-footer">
          <el-button class="cancelButton" @click="courseVisible = false">取消</el-button>
          <el-button class="confirmButton" @click="confirmBind">绑定</el-button>
        </span>
    </el-dialog>-->
  </div>
</template>

<script>
// 引入试题列表js
import schoolManager from "@/assets/js/manager/school/schoolManager.js";
export default {
  ...schoolManager
};
</script>

<style>
.schoolManager .confirmButton {
  padding: 0 0.7vw !important;
  height: 2.0833vw !important;
  background-color: #0d5570 !important;
  color: #ffffff !important;
  border-radius: 0.2083vw !important;
  font: normal normal normal 0.8333vw Source Han Sans CN !important;
}
.schoolManager .el-input__inner {
  font-size: 0.8333vw;
  height: 2.0833vw;
}
.schoolManager .el-input,
.schoolManager .el-select {
  width: 20vw !important;
}
.schoolManager .cancelButton {
  padding: 0 0.7vw !important;
  height: 2.0833vw !important;
  background-color: #ffffff !important;
  color: #000000 !important;
  border: 0.0521vw solid #cccccc !important;
  border-radius: 0.2083vw !important;
  font: normal normal normal 0.8333vw Source Han Sans CN !important;
}
.schoolManager .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #0d5570;
  color: #fff;
}
.schoolManager .el-table__body-wrapper {
  height: 67vh;
  overflow: scroll;
  position: relative;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow: scroll;
}
.schoolManager .el-dialog {
  /* padding: 1.5625vw 1.0417vw;
    padding-top: 0.2604vw;
    color: #606266;
    font-size: 0.7292vw;
    word-break: break-all;
    padding-bottom: 1.5625vw; */
  border-radius: 0.625vw !important;
}
.schoolManager .el-table__body-wrapper::-webkit-scrollbar {
  display: none;
}
.schoolManager .el-upload {
  border: 1px dashed #d9d9d9 !important;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.schoolManager .avatar-uploader {
  /* text-align:center; */
}
.schoolManager .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.schoolManager .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.schoolManager .avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.schoolManager .grantBox .el-table__body-wrapper {
  height: 25vw;
  overflow: scroll;
  position: relative;
}
.schoolManager .grantBox .el-checkbox__input.is-checked .el-checkbox__inner,
.schoolManager .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #0d5570;
  border-color: #0d5570;
}
.schoolManager .grantBox .el-table__body-wrapper::-webkit-scrollbar {
  display: none;
}
</style>
<style lang="scss" scoped>
// 引入试题列表组件css
@import "@/assets/css/manager/school/schoolManager.scss";
</style>