import { Message } from "element-ui"; //项目已经全局引入element的话可以不单独引入
import router from "@/router";
import { encryptCode, decryptCode } from "@/utils/function";
import { uploadSchoolLogoFilePath, request_sign } from "@/utils/const";
import API from '@/api/manager/school/schoolManager.js';
import { nprogressStart, nprogressClose } from "@/plugins/nprogress.js";
import { request } from "@/request/http";
export default {
    // 页面名称
    name: "schoolManager",
    inject: ['reload'],
    data() {
        return {
            // 实验中心名称
            exp_center_name: '',
            school_id: '',
            // 授权项目列表
            tableData: [],
            expandedkeys: [],
            // 授权全选
            checkedAll: false,
            // 授权项目弹窗
            grantVisible: false,
            sign: '',
            schoolName: '',
            schoolID: '',
            infoVisible: false,
            infoTitle: '',
            courseList: [],
            course_id: [],
            schoolPic: '',
            // 上传图片地址
            uploadSchoolLogoFilePath: process.env.VUE_APP_DOMAIN_URL + uploadSchoolLogoFilePath,
            // 同事列表数据
            schoolList: [],
            // 同事数据总数
            schoolListTotal: 0,
            // 根据同事内容搜索
            input: "",
            // 搜索同事列表
            searchColleagueList: [],
            // 当前页码数
            page: 1,
            // 当前一页显示多少条数据
            pageSize: 10,
            // 选择的同事id
            colleague_id: '',
            // 控制添加同事对话框的显示与隐藏
            logoVisible: false,
            logoTitle: '',
            courseVisible: false,
            user_id:'',
            options: [],
            loading: false,
        }
    },
    /**
     * @name: 生命周期创建
     * @desc: 页面创建时获取数据
     * @author: lxy
     * @date: 20210908
     */
    created() 
    {
        let self = this;
        self.input = this.$router.currentRoute.query.search;
        self.page = Number(this.$router.currentRoute.query.page) ? Number(this.$router.currentRoute.query.page) : 1;
        self.getData();
    },
    methods:
    {
        searchCourse(query)
        {
            var self = this;
            if (query == "") 
            {
                return;
            }
            let school_id = localStorage.getItem("get_school_id");
            let data = {
                name: query,
                school_id:school_id
            };
            self.loading = true;
            request("POST", `/index.php/adminSchool/searchCourse`, data)
            .then((result) => {
                if (result.code > 0) 
                {
                    self.loading = false;
                    for(let i = 0;i < result.list.length; i++)
                    {
                        self.courseList.push(result.list[i]);
                    }
                    // console.log(result)
                }
                // 关闭loading
                nprogressClose();
                self.$Loading.hide()
            })
        },

        remoteMethod(query)
        {
            const self = this;
            if (query === "") 
            {
                this.options = [];
            }
            let data = 
            {
                name: query,
            };
            self.loading = true;
            API.searchSchoolAdmin(data)
                .then((result) => {
                    if (result.code > 0) {
                        self.loading = false;
                        self.list = result.list;
                        self.options = self.list.map((item) => {
                            return { value: `${item.id}`, label: `${item.name +' - '+ item.personal_id}`, };
                        });
                    }
                    // 关闭loading
                    nprogressClose();
                    self.$Loading.hide()
                })
        },

        gograntProject(id) 
        {
            let self = this;
            self.expandedkeys = [];
            self.school_id = id
            // 弹窗
            let str = encryptCode({
                school_id: id,
            })
            // console.log({
            //     school_id: id,
            // });
            let data = { sign: request_sign, params: str }
            nprogressStart();
            self.$Loading.show();
            // 获取虚拟实验分类请求
            API.getSchoolExpList(data)
                .then((result) => {
                    nprogressClose();
                    self.$Loading.hide();
                    if (result.code > 0) {
                        let set = new Set()
                        result.data?.forEach((item) => {
                            item.explist?.forEach((key) => {
                                key.lab_id = key.id;
                                key.lab_name = key.name;
                                // key.checked = false;
                                key.buystatus = key.buystatus == 0 ? false : true
                            })

                            let expandedkeys = item.explist?.find((value) => {
                                return value.buystatus == true
                            })
                            if (expandedkeys) {
                                set.add(item.lab_id)
                            }

                            let index = item.explist?.findIndex((value) => {
                                return value.buystatus == 0
                            })
                            if (index >= 0) {
                                item.buystatus = false
                            }
                            else if (item.explist.length == 0) {
                                item.buystatus = false
                            }
                            else {
                                item.buystatus = true
                            }
                        })
                        self.expandedkeys = Array.from(set)
                        // console.log(self.expandedkeys);
                        self.tableData = result.data
                        self.grantVisible = true
                    }
                    else {
                        self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'warning',
                            displayTime: 1500
                        })
                    }
                })

        },

        grantProject() {
            let self = this;
            let explist = []
            this.tableData.forEach((item) => {
                item.explist?.forEach((key) => {
                    if (key.buystatus) {
                        explist.push(key.id)
                    }
                })
            })
            let str = encryptCode({
                school_id: self.school_id,
                explist: explist.toString()
            })
            // console.log(decryptCode(str));
            // console.log(self.school_id);
            // console.log({
            //     school_id: self.school_id,
            //     explist: explist.toString()
            // });
            let data = { sign: request_sign, params: str }
            nprogressStart();
            self.$Loading.show();
            // 获取虚拟实验分类请求
            API.grantSchoolExp(data)
                .then((result) => {
                    nprogressClose();
                    self.$Loading.hide();
                    if (result.code > 0) {
                        self.getData()
                        self.grantVisible = false
                        self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'success',
                            displayTime: 1500
                        })
                        // self.$router.push('/manager/user/grantProject?params=' +  str);
                    }
                    else {
                        self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'warning',
                            displayTime: 1500
                        })
                    }
                })
        },

        toggleChecked(info) 
        {
            if (!info.explist) {
                this.tableData.forEach((item) => {

                    let index = item.explist?.findIndex((key) => {
                        return key.buystatus == 0
                    })
                    if (index >= 0) {
                        item.buystatus = false;
                    }
                    else if (item.explist.length == 0) {
                        item.buystatus = false;
                    }
                    else {
                        item.buystatus = true;
                    }

                })
                // let index = this.tableData.findIndex((item)=>{
                //     return item.lab_id == exp.lab_id
                // })
            }

            if (info.buystatus) {
                if (info?.explist?.length > 0) {
                    info.explist.forEach((item) => {
                        item.buystatus = true
                    })
                }
            }
            else {
                if (info?.explist?.length > 0) {
                    info.explist.forEach((item) => {
                        item.buystatus = false
                    })
                }
            }

        },

        toggleAll(buystatus) 
        {
            this.tableData?.forEach((item) => {
                item.buystatus = buystatus;
                item.explist?.forEach((key) => {
                    key.buystatus = buystatus;
                })
            })
        },

        closeVisible() 
        {
            this.reload();
        },

        flush() 
        {
            try {
                let self = this;
                // 请求参数
                let data = {};
                nprogressStart();
                self.$Loading.show();
                // 刷新学校数据
                API.exportExpGameDataToExcel(data)
                    .then((result) => {
                        nprogressClose();
                        self.$Loading.hide();
                        if (result.code < 0) 
                        {
                            self.$Tips({
                                // 消息提示内容
                                message: result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'warning',
                                displayTime: 1500
                            })
                        }
                    })
            }
            catch (error) {
                Message.error('系统错误') ;
            }
            // window.open('https://app.mools.net/backend/public/index.php/adminSchool/exportExpGameDataToExcel');
        },

        indexMethod(index) 
        {
            let currentPage = this.page; // 当前页码
            let pageSize = this.pageSize; // 每页条数
            return (index + 1) + (currentPage - 1) * pageSize; // 返回表格序号
        },

        addSchool() 
        {
            this.schoolName = '';
            this.schoolID = '';
            this.schoolPic = '';
            this.course_id = '';
            this.courseList = '';
            this.exp_center_name = '';
            this.infoTitle = '添加学校'
            this.infoVisible = true;
            this.sign = 'add';
        },

        handleCurrentChange(val) 
        {
            const self = this;
            self.page = val;
            self.getData();
            // 路由传参
            self.$router.push({
                path: "/manager/schoolManager",
                query: {
                    search: self.input,
                    page: self.page
                }
            });
        },

        bindCourse() 
        {
            this.courseVisible = true
        },

        confirmBind() 
        {
            const self = this;
            let data = {
                courseid: self.course_id,
                project_id: self.project_id
            };
            // 开启loading
            nprogressStart();
            self.$Loading.show()
            API.updateCourseProject(data)
                .then((result) => {
                    // 关闭loading
                    nprogressClose();
                    self.$Loading.hide()
                    if (result.code > 0) {
                        // Message.success(result.msg);
                        self.dialogProjectVisible = false;
                        self.project_id = [];
                        self.getCourseProjectList();
                    }
                    else {
                        self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'warning',
                            displayTime: 1500
                        })
                    }
                })
                .catch(() => {
                    nprogressClose();
                    self.$Loading.hide()
                })
        },

        uploadPic(row) 
        {
            this.schoolPic = '';
            this.logoVisible = true;
            this.schoolID = row.id;
            this.logoTitle = '上传学校logo / ' + row.name
        },

        updatePic(row) 
        {
            const self = this;
            self.sign = 'update';
            let data = {
                name: row.name,
                id: row.id
            };
            self.user_id = "";
            self.course_id = [];
            // self.schoolName = []
            // 开启loading
            nprogressStart();
            self.$Loading.show()
            API.getSchoolInfo(data)
                .then((result) => {
                    // 关闭loading
                    nprogressClose();
                    self.$Loading.hide()
                    if (result.code > 0) 
                    {
                        self.list = result.userList;
                        if(result.schoolInfo.schoo_admin_id !== null)
                        {
                            self.user_id = result.schoolInfo.schoo_admin_id+"";
                        }
                        self.options = self.list.map((item) => {
                            return { value: `${item.id}`, label: `${item.name +' - '+ item.personal_id}`, };
                        });

                        // console.log(result.schoolInfo.id)
                        localStorage.setItem("get_school_id",result.schoolInfo.id);

                        let course_id = []
                        self.infoVisible = true;
                        self.infoTitle = '修改学校信息 / ' + row.name
                        self.courseList = result.courseList
                        self.schoolID = result.schoolInfo.id
                        self.schoolName = result.schoolInfo.name
                        self.schoolPic = result.schoolInfo.pic
                        self.exp_center_name = result.schoolInfo.exp_center_name
                        result.schoolInfo.course_id.split(',')?.forEach((item) => {
                            course_id.push(item - 0)
                        })
                        self.course_id = course_id;
                        
                    }
                    else 
                    {
                        self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'warning',
                            displayTime: 1500
                        })
                    }
                })
                .catch(() => {
                    nprogressClose();
                    self.$Loading.hide()
                })
        },

        confirmUpdate() 
        {
            const self = this;
            let data = {
                name: this.schoolName,
                id: this.schoolID,
                course_id: this.course_id.toString(),
                pic: this.schoolPic,
                sign: this.sign,
                user_id: self.user_id,
                exp_center_name: this.exp_center_name
            };
            // 开启loading
            nprogressStart();
            self.$Loading.show()
            API.updateSchoolInfo(data)
                .then((result) => {
                    // 关闭loading
                    nprogressClose();
                    self.$Loading.hide()
                    if (result.code > 0) {
                        this.infoVisible = false;
                        this.getData()
                    }
                    else {
                        this.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'warning',
                            displayTime: 1500
                        })
                    }
                })
                .catch(() => {
                    nprogressClose();
                    self.$Loading.hide()
                })
        },

        handleAvatarSuccess(res) 
        {
            const self = this;
            // console.log(res);
            if (res.code > 0) {
                self.schoolPic = res.data;
                // Message.success(res.msg);
            } else {
                // Message.error(res.msg);
            }
        },

        beforeAvatarUpload(file) 
        {
            let isJPG = true
            if (file.type !== "image/jpeg" && file.type !== "image/png" && file.type !== "image/jpg" && file.type !== "image/gif" && file.type !== "image/bmp") {
                isJPG = false
            }
            const isLt2M = file.size / 1024 / 1024 < 5;
            if (!isJPG) {
                // Message.success("上传头像图片只能是 JPG/png/gif/bmp 格式!");
                this.$Tips({
                    // 消息提示内容
                    message: "上传logo图片只能是 JPG/png/gif/bmp 格式",
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType: 'warning',
                    button: [
                        {
                            type: 1,
                            text: '知道了'
                        }
                    ]
                })
            }
            if (!isLt2M) {
                // Message.error("上传头像图片大小不能超过 5MB!");
                this.$Tips({
                    // 消息提示内容
                    message: "上传logo图片大小不能超过 5MB",
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType: 'warning',
                    button: [
                        {
                            type: 1,
                            text: '知道了'
                        }
                    ]
                })
            }
            return isJPG && isLt2M;
        },

        getData() 
        {
            try {
                let self = this;
                // 请求参数
                let data = {
                    name: self.input,
                    page: self.page,
                    pagesize: self.pageSize
                };
                nprogressStart();
                self.$Loading.show();
                // 获取同事列表请求
                API.getSchoolList(data)
                    .then((result) => {
                        nprogressClose();
                        self.$Loading.hide();
                        if (result.code > 0) {
                            self.schoolList = result.list
                            self.schoolListTotal = result.total[0].number - 0
                        }
                        else {
                            self.$Tips({
                                // 消息提示内容
                                message: result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'warning',
                                displayTime: 1500
                            })
                        }
                    })
            }
            catch (error) {
                // Message.error('系统错误') 
            }
        },

        deletePic(row) 
        {
            try {
                let self = this;
                // 请求参数
                let data = {
                    id: row.id
                };
                // 删除同事请求
                self.$confirm(`确认要删除${row.name}吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    API.delSchool(data)
                        .then((result) => {
                            if (result.code > 0) {
                                // Message.success('删除成功');
                                self.getData();
                                self.$Tips({
                                    // 消息提示内容
                                    message: result.msg,
                                    // 消息提示类型（success-成功,warning-警告/失败）
                                    messageType: 'success',
                                    displayTime: 1500
                                })
                            }
                            else {
                                self.$Tips({
                                    // 消息提示内容
                                    message: result.msg,
                                    // 消息提示类型（success-成功,warning-警告/失败）
                                    messageType: 'warning',
                                    displayTime: 1500
                                })
                            }
                        })
                }).catch(() => {
                    // Message.info('已取消');        
                });
            }
            catch (error) {
                // Message.error('系统错误');
            }
        },

        confirmAdd() 
        {
            try {
                let self = this;
                // 请求参数
                let data = {
                    id: self.schoolID,
                    pic: self.schoolPic
                };
                // 添加学校请求
                API.updateSchoolLogo(data)
                    .then((result) => {
                        if (result.code > 0) {
                            self.logoVisible = false;
                            self.getData();
                            self.$Tips({
                                // 消息提示内容
                                message: result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'success',
                                displayTime: 1500
                            })
                        }
                        else {
                            // Message.error('系统错误')
                            self.$Tips({
                                // 消息提示内容
                                message: result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'warning',
                                displayTime: 1500
                            })
                        }
                    })
            }
            catch (error) {
                // Message.error('系统错误') 
            }
        },

        searchColleague(e) 
        {
            try {
                let self = this;
                let data = {
                    name: e
                }
                if (e) {
                    API.searchColleague(data)
                        .then((result) => {
                            if (result.code > 0) {
                                self.searchColleagueList = result.data
                            }
                            else {
                                // Message.error('系统错误')
                                self.$Tips({
                                    // 消息提示内容
                                    message: result.msg,
                                    // 消息提示类型（success-成功,warning-警告/失败）
                                    messageType: 'warning',
                                    displayTime: 1500
                                })
                            }
                        })
                }
            }
            catch (error) {
                // Message.error('系统错误')
            }
        },

        handleSearchColleague() 
        {
            let self = this;
            self.page = 1;
            self.$router.push({
                path: "/manager/schoolManager",
                query: {
                    search: self.input,
                    page: self.page
                }
            });
            self.getData()
        },
    }
};