import { request } from "@/request/http";

/**
 * @name: 学校管理接口
 * @author: LXY
 * @date: 2022-02-28 
 */
export default {
  getSchoolList: (data) => {
    return request("POST", `/index.php/adminSchool/getSchoolList`, data);
  },

  updateSchoolInfo: (data) => {
    return request("POST", `/index.php/adminSchool/updateSchoolInfo`, data);
  },

  getSchoolInfo: (data) => {
    return request("POST", `/index.php/adminSchool/getSchoolInfo`, data);
  },

  delSchool: (data) => {
    return request("POST", `/index.php/adminSchool/delSchool`, data);
  },

  updateSchoolLogo: (data) => {
    return request("POST", `/index.php/adminSchool/updateSchoolLogo`, data);
  },

  getSchoolExpList: (data) => {
    return request("POST", `/index.php/adminSchool/getSchoolExpList`, data);
  },

  grantSchoolExp: (data) => {
    return request("POST", `/index.php/adminSchool/grantSchoolExp`, data);
  },

  exportExpGameDataToExcel: (data) => {
    return request("GET", `/index.php/adminSchool/exportExpGameDataToExcel`, data);
  },

  searchSchoolAdmin: (data) => {
    return request("POST", `/index.php/adminSchool/searchSchoolAdmin`, data);
  },
  
};